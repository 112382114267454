<template>

  <validation-observer ref="VFormCatatanPemberian">
    <b-form @submit.prevent="doSubmit">
      <div class="row">
        <div class="col-md-12">
          <small class="text-info">*Data Pemberian Obat Pasien tidak termasuk Alat Kesehatan</small>        
        </div>
      </div>
      <div v-for="(v,k) in (isParent.resepObat||[])" :key="k" class="row mb-3">
        <div class="col-md-12">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="width:20%;">
                  <div class="label_code label_code_sm m-0">
                    <h2>{{k+1}}</h2>
                  </div>
                  <span v-if="v.arantfarr_is_stop == 'Y'" v-b-tooltip.hover :title="v.arantfarr_is_stop_reason" class="badge badge-danger ml-2">Obat Dihentikan</span>
                </td>
                <td style="width:40%;">
                  <div class="result_tab">
                    <h4>Nama Obat</h4>
                    <p>{{v.arantfarr_nama||"-"}}</p>
                  </div>
                </td>
                <td style="width:20%;">
                  <div class="result_tab">
                    <h4>Frekuensi</h4>
                    <p>{{v.mdo_name||"-"}}</p>
                  </div>
                </td>
                <td style="width:20%;">
                  <div class="result_tab">
                    <h4>Jumlah</h4>
                    <p>{{v.arantfarr_jumlah||"-"}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12 mb-2">
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th class="table-info" colspan="6">Waktu Pemberian</th>
              </tr>
              <tr>
                <th>Tanggal</th>
                <th>Pagi</th>
                <th>Siang</th>
                <th>Sore</th>
                <th>Malam</th>
                <th style="width:5%;" >Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v1,k1) in (v.arantfarr_pemberian_obat||[])" :key="k+k1">
                <td>
                  <div class="input-group bootstrap-timepicker timepicker">
                    <span class="input-group-prepend input-group-addon">
                      <span class="input-group-text"><i class="icon-calendar"></i></span>
                    </span>
                    <datepicker @input="autofill($event,k,k1,'date')" input-class="form-control transparent"
                        placeholder="Pilih Tanggal" class="my-datepicker"
                        calendar-class="my-datepicker_calendar" v-model="v1.date_farmasi">
                    </datepicker>
                  </div>
                  <VValidate :name="'Tanggal #'+(k)+k1" v-model="v1.date_farmasi" :rules="{required:1}" />
                </td>
                <td>
                  <div class="input-group bootstrap-timepicker timepicker">
                    <vue-timepicker manual-input @input="autofill($event,k,k1,'pagi')" format="HH:mm" input-class="form-control" v-model="v1.pagi_farmasi">
                    </vue-timepicker>
                  </div>
                  <!--
                  <VValidate message="Waktu Pagi Harus Diisi" :name="'Waktu pagi #'+(k)+k1" v-model="v1.pagi_farmasi" :rules="{required:1}" />
                  -->
                </td>
                <td>
                  <div class="input-group bootstrap-timepicker timepicker">
                    <vue-timepicker manual-input @input="autofill($event,k,k1,'siang')" format="HH:mm" input-class="form-control" v-model="v1.siang_farmasi">
                    </vue-timepicker>
                  </div>
                  <!--
                  <VValidate message="Waktu Siang Harus Diisi" :name="'Waktu siang #'+(k)+k1" v-model="v1.siang_farmasi" :rules="{required:1}" />
                  -->
                </td>
                <td>
                  <div class="input-group bootstrap-timepicker timepicker">
                    <vue-timepicker manual-input @input="autofill($event,k,k1,'sore')" format="HH:mm" input-class="form-control" v-model="v1.sore_farmasi">
                    </vue-timepicker>
                  </div>
                  <!--
                  <VValidate message="Waktu Sore Harus Diisi" :name="'Waktu sore #'+(k)+k1" v-model="v1.sore_farmasi" :rules="{required:1}" />
                  -->
                </td>
                <td>
                  <div class="input-group bootstrap-timepicker timepicker">
                    <vue-timepicker manual-input @input="autofill($event,k,k1,'malam')" format="HH:mm" input-class="form-control" v-model="v1.malam_farmasi">
                    </vue-timepicker>
                  </div>

                  <!--
                  <VValidate message="Waktu Malam Harus Diisi" :name="'Waktu malam #'+(k)+k1" v-model="v1.malam_farmasi" :rules="{required:1}" />
                  -->
                </td>
                <td class="text-center">
                  <a href="javascript:;" @click="v.arantfarr_pemberian_obat.splice(k1,1)" class="btn btn-icon btn-sm rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover title="Hapus Tindakan"><i class="icon-bin"></i></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6" class="text-center">
                  <a href="javascript:;" @click="addObat(k)" class="btn btn-sm alpha-info border-info"><i
                      class="icon-plus2 mr-1"></i>Tambah</a>

                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="text-right">
            <button type="button" @click="isParent.openModal = false" class="btn" data-dismiss="modal">Tutup</button>
            <button type="button" @click="simpanSementara()" class="btn btn-primary ml-1">Simpan Sementara  
            </button>
            <button type="submit" class="btn btn-primary ml-1">Submit Data <i class="icon-paperplane ml-2"></i> 
            </button>
          </div>
        </div>
      </div>
    </b-form>
  </validation-observer>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker'


export default{
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  components: {
    VueTimepicker,Datepicker
  },
  methods: {
    addObat(k){
      let date_farmasi = moment().format('YYYY-MM-DD')
      let date = moment().format('YYYY-MM-DD')

      let pagi_farmasi = '06:00'
      let siang_farmasi = '12:00'
      let sore_farmasi = '17:00'
      let malam_farmasi = '21:00'

      let pagi = '06:00'
      let siang = '12:00'
      let sore = '17:00'
      let malam = '21:00'

      if(this.isParent.resepObat[k]['arantfarr_pemberian_obat'].length){
        let lenObat = this.isParent.resepObat[k]['arantfarr_pemberian_obat'].length
        let valObat = this.isParent.resepObat[k]['arantfarr_pemberian_obat'][lenObat - 1]
        
        date_farmasi = moment(valObat.date_farmasi).add(24, 'hours').format('YYYY-MM-DD') 
        date = moment(valObat.date).add(24, 'hours').format('YYYY-MM-DD') 

        pagi_farmasi = valObat.pagi_farmasi
        siang_farmasi = valObat.siang_farmasi
        sore_farmasi = valObat.sore_farmasi
        malam_farmasi = valObat.malam_farmasi
        
        pagi = valObat.pagi
        siang = valObat.siang
        sore = valObat.sore
        malam = valObat.malam
      }

      this.isParent.resepObat[k]['arantfarr_pemberian_obat'].push({
        date_farmasi : date_farmasi,

        pagi_farmasi : pagi_farmasi,
        siang_farmasi : siang_farmasi,
        sore_farmasi : sore_farmasi,
        malam_farmasi : malam_farmasi,
        
        date : date,

        pagi : pagi,
        pagi_checked : "N",
        pagi_by : null,
        pagi_is_done :  false, 
        pagi_status : "B",
        
        siang : siang,
        siang_checked : "N",
        siang_by : null,
        siang_is_done :  false, 
        siang_status : "B",
        
        sore : sore,
        sore_checked : "N",
        sore_by : null,
        sore_is_done :  false, 
        sore_status : "B",

        malam : malam,
        malam_checked : "N",
        malam_by : null,
        malam_is_done :  false, 
        malam_status : "B",
      })
    },
    simpanSementara(){
      let data = this.isParent.rowEdit
      data.type = 'submit-pemberian'
      data.isSubmit = false
      data.resepObat = this.isParent.resepObat
      this.isParent.loadingOverlay = true
      Gen.apiRest(
      "/do/" + this.isParent.modulePage, {
          data: data
      },
      "POST"
      ).then(res => {
          this.isParent.loadingOverlay = false
          let resp = res.data
          resp.statusType = 200
          this.$swal({
            title: resp.message,
            icon: resp.status,
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then(result => {
            this.isParent.openModal = false
            this.isParent.apiGet()
          })
      }).catch(err => {
          this.isParent.loadingOverlay = false
          if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
          }
          this.isParent.doSetAlertForm(err)
      })
    },
    doSubmit(){
      this.$refs['VFormCatatanPemberian'].validate().then(success => {  
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowEdit
              data.type = 'submit-pemberian'
              data.resepObat = this.isParent.resepObat
              data.isSubmit = true

              this.isParent.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.isParent.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.isParent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.isParent.openModal = false
                    this.isParent.apiGet()
                  })
              }).catch(err => {
                  this.isParent.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.isParent.doSetAlertForm(err)
              })
            }
          }) 
        }
      })
    },
    checkResepObat(){
      for(let i = 0; i < (this.isParent.resepObat||[]).length; i++){
        if(!(this.isParent.resepObat[i]['arantfarr_pemberian_obat']||[]).length){
          this.addObat(i)
        }
      }
    },
    autofill(e,k,k1,field){
      this.isParent.resepObat[k]['arantfarr_pemberian_obat'][k1][field] = e 
    }
  },
  mounted() {
    this.checkResepObat()
  },
}
</script>